import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, Space, Typography } from "antd";
import * as React from "react";
import styled from "styled-components";
import StockIcon from "src/assets/svg/device-management/stock-icon.svg";
const StockSummary = ({ ...props }) => (_jsxs(StockSummaryContainer, { ...props, children: [_jsxs(Space, { align: "start", children: [_jsx(StockIcon, {}), _jsx(Typography.Title, { level: 5, children: "Active Stock Summary" })] }), _jsx(Divider, {}), _jsxs(StockSummaryContent, { children: [_jsxs(Space, { children: [_jsx(Typography.Text, { children: "Total Units" }), _jsx(UnitBox, { children: "0" })] }), _jsxs(Space, { children: [_jsx(Typography.Text, { children: "Used Units" }), _jsx(UnitBox, { children: "0" })] }), _jsxs(Space, { children: [_jsx(Typography.Text, { children: "Remaining Units" }), _jsx(UnitBox, { children: "0" })] })] })] }));
export default StockSummary;
const StockSummaryContainer = styled.div `
  width: 100%;
  margin: 0;
  margin-bottom: 3rem;
`;
const UnitBox = styled.div `
  background-color: #ffffff;
  border-radius: 0.25rem;
  padding: 0.5rem 2rem;
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0 1rem;
`;
const StockSummaryContent = styled.div `
  width: 100%;
  background-color: #edeef0;
  padding: 1rem 2rem;
  border-radius: 0.25rem;
  display: flex;
  justify-content: space-between;

  span {
    font-weight: 700;
    color: #4b5767;
    font-size: 1rem;
  }
  & > div:nth-of-type(1) ${UnitBox} {
    color: #4b5767;
  }

  & > div:nth-of-type(2) ${UnitBox} {
    color: #156cc9;
  }

  & > div:nth-of-type(3) ${UnitBox} {
    color: #35b700;
  }
`;
