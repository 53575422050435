import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, Space, Typography } from "antd";
import * as React from "react";
import styled from "styled-components";
import { UrineKitIcon } from "src/assets/svg/test-type";
import { PageLayoutWithExtraTab } from "src/components/layouts/PageLayout/PageLayoutWithExtraTab";
import { breadcrumbs } from "..";
import AddStockForm from "../components/AddStockForm";
const UrineSampleVialKitStock = () => (_jsx(PageLayoutWithExtraTab, { routes: breadcrumbs, tabLevel: 2, children: _jsxs(PageContainer, { children: [_jsxs(Space, { children: [_jsx(UrineKitIcon, {}), _jsx(Typography.Title, { level: 5, children: "Urine Sample Vial Kits" })] }), _jsx(Divider, {}), _jsx(AddStockForm, { dataSource: [] })] }) }));
export default UrineSampleVialKitStock;
const PageContainer = styled.div `
  width: 100%;
`;
