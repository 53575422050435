import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Navigate, Route, Routes } from "react-router-dom";
import MainLayout from "src/components/layouts/MainLayout";
import Breathalyser from "./Breathalyser";
import HairCollectionKit from "./HairCollectionKit";
import OralCollectionDevicePage from "./OralFluidCollection";
import OralScreenDevicePage from "./OralFluidDrugScreen";
import UrineCollectionCup from "./UrineCollectionCups";
import UrineScreenDevicePage from "./UrineDrugScreenDevice";
import UrineSampleVialKit from "./UrineSampleVialKit";
const TestingDeviceRoute = () => (_jsx(MainLayout, { children: _jsxs(Routes, { children: [_jsxs(Route, { path: "urine", children: [_jsx(Route, { index: true, element: _jsx(Navigate, { to: "screen-device", replace: true }) }), _jsx(Route, { path: "screen-device/*", element: _jsx(UrineScreenDevicePage, {}) }), _jsx(Route, { path: "collection-cup/*", element: _jsx(UrineCollectionCup, {}) }), _jsx(Route, { path: "vial-kit/*", element: _jsx(UrineSampleVialKit, {}) })] }), _jsxs(Route, { path: "oral", children: [_jsx(Route, { index: true, element: _jsx(Navigate, { to: "screen-device", replace: true }) }), _jsx(Route, { path: "screen-device/*", element: _jsx(OralScreenDevicePage, {}) }), _jsx(Route, { path: "collection-cup/*", element: _jsx(OralCollectionDevicePage, {}) })] }), _jsxs(Route, { path: "hair", children: [_jsx(Route, { index: true, element: _jsx(Navigate, { to: "collection-kit", replace: true }) }), _jsx(Route, { path: "collection-kit/*", element: _jsx(HairCollectionKit, {}) })] }), _jsxs(Route, { path: "breath", children: [_jsx(Route, { index: true, element: _jsx(Navigate, { to: "breathalyser", replace: true }) }), _jsx(Route, { path: "breathalyser/*", element: _jsx(Breathalyser, {}) })] }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/testing-devices/urine/", replace: true }) })] }) }));
export default TestingDeviceRoute;
export const breadcrumbs = [
    {
        path: "/testing-devices",
        name: "Device Management",
        level: 1,
    },
    {
        path: "/urine",
        name: "Urine",
        level: 2,
        subRoutes: [
            { path: "/screen-device", name: "Urine Drug Screen Devices", level: 3 },
            { path: "/collection-cup", name: "Urine Collection Cups", level: 3 },
            { path: "/vial-kit", name: "Urine Sample Vial Kits", level: 3 },
        ],
    },
    {
        path: "/oral",
        name: "Oral Fluid",
        level: 2,
        subRoutes: [
            { path: "/screen-device", name: "Oral Fluid Drug Screen Devices", level: 3 },
            { path: "/collection-cup", name: "Oral Fluid Collection Devices", level: 3 },
        ],
    },
    {
        path: "/hair",
        name: "Hair",
        level: 2,
        subRoutes: [{ path: "/collection-kit", name: "Hair Collection Kits", level: 3 }],
    },
    {
        path: "/breath",
        name: "Breath",
        level: 2,
        subRoutes: [{ path: "/breathalyser", name: "Breathalysers", level: 3 }],
    },
];
