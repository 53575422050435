import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, DatePicker, Form, Input, message, Space, TreeSelect } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import UrineCupIcon from "src/assets/svg/device-management/empty-urine-device.svg";
import InstructionIcon from "src/assets/svg/device-management/instruction.svg";
import OperatedInstructionIcon from "src/assets/svg/device-management/operation-instruction.svg";
import PdfIcon from "src/assets/svg/device-management/pdf.svg";
import UploadedPdfIcon from "src/assets/svg/device-management/uploaded-pdf.svg";
import { SpaceBetweenContainer } from "src/components/Container/Flex";
import SADivider from "src/components/Form/SADivider";
import SAFormItem from "src/components/Form/SAFormItem";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import SASwitch from "src/components/Form/Table/SASwitch";
import { OverlayLoading } from "src/components/Loading/OverlayLoading";
import useGoBackToPreviousPath from "src/hooks/useGoBackToPath";
import { deleteFile } from "src/infra/file";
import { UploadPrefix } from "src/infra/file/types";
import { selectCollectionOrganization, selectCollectionPoints, selectIsLoading, selectTestTypeByRecordIndex, } from "src/store/app/selector";
import { setIsLoading } from "src/store/app/slice";
import { TestTypeRecordIndex } from "src/store/app/types";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { PageLayoutWithTabAndBack } from "src/components/layouts/PageLayout";
import AddStockForm from "../../components/AddStockForm";
import FileOptionsDropdown from "../../components/FileOptionsDropdown";
import FileUploadDrawer from "../../components/FileUploadDrawer";
import { defaultUploadingFile, DeviceManagerView, FileMenuOption, UploadFileType, } from "../../typing";
import { createTestingDevice, getTestingDeviceById, updateTestingDevice } from "../store/action";
import { selectTestingDeviceDetail } from "../store/selector";
import { setDeviceDetails } from "../store/slice";
const UrineDeviceManager = () => {
    const [form] = Form.useForm();
    const [searchParams] = useSearchParams();
    const { view } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const goBackToDashboard = useGoBackToPreviousPath();
    const isLoading = useAppSelector(selectIsLoading);
    const deviceInfo = useAppSelector(selectTestingDeviceDetail);
    const collectionPoints = useAppSelector(selectCollectionPoints);
    const collectionOrg = useAppSelector(selectCollectionOrganization);
    const testTypes = useAppSelector(selectTestTypeByRecordIndex);
    const [isOpenUploadPanel, setIsOpenUploadPanel] = useState(false);
    const [uploadingFile, setUploadingFile] = useState(defaultUploadingFile);
    useEffect(() => {
        const loadData = async () => {
            dispatch(setIsLoading(true));
            const testingDeviceId = searchParams.get("id");
            if (view === DeviceManagerView.Update && testingDeviceId) {
                await dispatch(getTestingDeviceById(testingDeviceId));
            }
            dispatch(setIsLoading(false));
        };
        loadData();
    }, [view]);
    useEffect(() => {
        form.setFieldsValue({ ...deviceInfo });
    }, [deviceInfo]);
    const onMenuItemClick = async (option) => {
        switch (option) {
            case FileMenuOption.Upload: {
                setIsOpenUploadPanel(true);
                return;
            }
            case FileMenuOption.View: {
                if (!uploadingFile.url)
                    return;
                window.open(uploadingFile.url, "_blank");
                return;
            }
            case FileMenuOption.Delete: {
                if (!uploadingFile.url || !uploadingFile.key)
                    return;
                await deleteFile(uploadingFile.url);
                if (uploadingFile.key === "sideImageUrls" && uploadingFile.url) {
                    const newSideImageUrls = deviceInfo.sideImageUrls?.filter((url) => url !== uploadingFile.url);
                    await dispatch(setDeviceDetails({
                        [uploadingFile.key]: newSideImageUrls,
                    }));
                    setUploadingFile(defaultUploadingFile);
                    return;
                }
                await dispatch(setDeviceDetails({
                    [uploadingFile.key]: "",
                }));
                setUploadingFile(defaultUploadingFile);
                return;
            }
        }
    };
    return (_jsx(PageLayoutWithTabAndBack, { heading: "Urine Drug Screen Devices", children: _jsxs(NewDeviceContainer, { children: [_jsxs(CreateFormWrapper, { children: [isLoading && _jsx(OverlayLoading, {}), _jsxs(Form, { form: form, labelCol: { span: 24 }, wrapperCol: { span: 24 }, onFinishFailed: () => {
                                window.scrollTo(0, 0);
                                message.error("The form has not been finished yet");
                            }, onFinish: async () => {
                                dispatch(setIsLoading(true));
                                if (view === DeviceManagerView.Update) {
                                    await dispatch(updateTestingDevice({
                                        ...deviceInfo,
                                        testTypeId: testTypes[TestTypeRecordIndex.RapidUrineDrugScreen],
                                    }));
                                }
                                else {
                                    await dispatch(createTestingDevice({
                                        ...deviceInfo,
                                        testTypeId: testTypes[TestTypeRecordIndex.RapidUrineDrugScreen],
                                    }));
                                }
                                dispatch(setIsLoading(false));
                                navigate("/testing-devices/urine-screen");
                            }, children: [_jsxs(HeaderRow, { children: [_jsx(SAColumn, { span: 12, children: _jsx(SAFormItem, { label: "Device Name", name: "deviceName", requiredText: "Please fill in the device name", children: _jsx(StyledInput, { onChange: (event) => {
                                                        dispatch(setDeviceDetails({
                                                            deviceName: event.target.value,
                                                        }));
                                                    } }) }) }), _jsx(SAColumn, { span: 6, children: _jsx(SAFormItem, { name: "isInUse", valuePropName: "checked", children: _jsx(SASwitch, { uncheckedLabel: "In Use", onChange: (isChecked) => {
                                                        dispatch(setDeviceDetails({
                                                            isInUse: isChecked,
                                                        }));
                                                    } }) }) }), _jsx(SAColumn, { span: 6, align: "flex-end", children: _jsxs(Space, { children: [_jsx(SAFormItem, { children: _jsx(Button, { danger: true, onClick: () => goBackToDashboard("urine-screen"), children: "Cancel" }) }), _jsx(SAFormItem, { children: _jsx(Button, { type: "primary", htmlType: "submit", children: "Save" }) })] }) }), _jsx(SAColumn, { span: 24, children: _jsx(SADivider, {}) })] }), _jsxs(SARow, { children: [_jsxs(SAColumn, { span: 12, children: [_jsx(SAFormItem, { label: "Lot Number", name: "lotNumber", requiredText: "Please fill in the lot number", children: _jsx(StyledInput, { onChange: (event) => {
                                                            dispatch(setDeviceDetails({
                                                                lotNumber: event.target.value,
                                                            }));
                                                        } }) }), _jsx(SAFormItem, { label: "Expiry Date", requiredText: "Please select the expiry date", children: _jsx(DatePicker, { format: "DD MMM YYYY", value: deviceInfo.expireAt ? moment(deviceInfo.expireAt) : null, onChange: (selectedDate) => {
                                                            if (!selectedDate)
                                                                return;
                                                            dispatch(setDeviceDetails({
                                                                expireAt: new Date(selectedDate.toISOString()),
                                                            }));
                                                        } }) }), _jsx(SAFormItem, { label: "Expiry Date Reminder", name: "isReminderExpire", valuePropName: "checked", children: _jsxs(SpaceBetweenContainer, { children: [_jsx(SASwitch, { checked: deviceInfo.isReminderExpire, onChange: (isChecked) => {
                                                                    dispatch(setDeviceDetails({
                                                                        isReminderExpire: isChecked,
                                                                    }));
                                                                } }), _jsx(DatePicker, { format: "DD MMM YYYY", value: deviceInfo.reminderAt ? moment(deviceInfo.reminderAt) : null, disabled: !deviceInfo.isReminderExpire, onChange: (reminderAt) => {
                                                                    dispatch(setDeviceDetails({
                                                                        reminderAt: reminderAt?.toDate(),
                                                                    }));
                                                                } })] }) }), _jsx(SAFormItem, { label: "Service Office/Clinic", name: "collectionPointIds", requiredText: "Please select an office", children: _jsx(TreeSelect, { treeData: [
                                                            {
                                                                title: collectionOrg.collectionName,
                                                                value: collectionOrg.id,
                                                                children: collectionPoints.map((point) => ({
                                                                    title: point.collectionPointName,
                                                                    value: point.id,
                                                                })),
                                                            },
                                                        ], treeCheckable: true, showCheckedStrategy: TreeSelect.SHOW_CHILD, treeDefaultExpandAll: true, placeholder: "Please select collection points", onChange: (selectedIds) => {
                                                            dispatch(setDeviceDetails({
                                                                collectionPointIds: selectedIds,
                                                            }));
                                                        }, style: { width: "100%" } }) })] }), _jsx(SAColumn, { span: 6, children: _jsx(SAFormItem, { label: "Picture of the Device", name: "fontImageUrl", children: _jsx(FileOptionsDropdown, { src: deviceInfo.fontImageUrl, onMenuItemClick: (option) => {
                                                        setUploadingFile({
                                                            url: deviceInfo.fontImageUrl ?? null,
                                                            key: "fontImageUrl",
                                                            fileType: UploadFileType.Image,
                                                        });
                                                        onMenuItemClick(option);
                                                    }, Fallback: UrineCupIcon, size: "large", type: UploadFileType.Image }) }) }), _jsxs(SAColumn, { span: 3, children: [_jsx(SAFormItem, { label: " ", name: "sideImageUrls[0]", children: _jsx(FileOptionsDropdown, { src: deviceInfo.sideImageUrls ? deviceInfo.sideImageUrls[0] : "", onMenuItemClick: (option) => {
                                                            const validUrl = deviceInfo.sideImageUrls
                                                                ? deviceInfo.sideImageUrls[0]
                                                                : null;
                                                            setUploadingFile({
                                                                url: validUrl,
                                                                key: "sideImageUrls",
                                                                fileType: UploadFileType.Image,
                                                            });
                                                            onMenuItemClick(option);
                                                        }, Fallback: UrineCupIcon, size: "small", type: UploadFileType.Image }) }), _jsx(SAFormItem, { name: "sideImageUrls[1]", children: _jsx(FileOptionsDropdown, { src: deviceInfo.sideImageUrls ? deviceInfo.sideImageUrls[1] : "", onMenuItemClick: (option) => {
                                                            const validUrl = deviceInfo.sideImageUrls
                                                                ? deviceInfo.sideImageUrls[1]
                                                                : null;
                                                            setUploadingFile({
                                                                url: validUrl,
                                                                key: "sideImageUrls",
                                                                fileType: UploadFileType.Image,
                                                            });
                                                            onMenuItemClick(option);
                                                        }, Fallback: UrineCupIcon, size: "small", type: UploadFileType.Image }) })] }), _jsxs(SAColumn, { span: 3, children: [_jsx(SAFormItem, { label: " ", name: "sideImageUrls[2]", children: _jsx(FileOptionsDropdown, { src: deviceInfo.sideImageUrls ? deviceInfo.sideImageUrls[2] : "", onMenuItemClick: (option) => {
                                                            const validUrl = deviceInfo.sideImageUrls
                                                                ? deviceInfo.sideImageUrls[2]
                                                                : null;
                                                            setUploadingFile({
                                                                url: validUrl,
                                                                key: "sideImageUrls",
                                                                fileType: UploadFileType.Image,
                                                            });
                                                            onMenuItemClick(option);
                                                        }, Fallback: UrineCupIcon, size: "small", type: UploadFileType.Image }) }), _jsx(SAFormItem, { name: "instructionUrl", children: _jsx(FileOptionsDropdown, { src: deviceInfo.instructionUrl, onMenuItemClick: (option) => {
                                                            setUploadingFile({
                                                                url: deviceInfo.instructionUrl ?? null,
                                                                key: "instructionUrl",
                                                                fileType: UploadFileType.Pdf,
                                                            });
                                                            onMenuItemClick(option);
                                                        }, Fallback: deviceInfo.instructionUrl ? OperatedInstructionIcon : InstructionIcon, size: "small", type: UploadFileType.Pdf }) })] }), _jsx(SAColumn, { span: 24, children: _jsx(SADivider, {}) })] }), _jsxs(SARow, { children: [_jsxs(SAColumn, { span: 12, children: [_jsx(SAFormItem, { label: "ARTG Number", name: "artgNumber", children: _jsx(StyledInput, { onChange: (event) => {
                                                            dispatch(setDeviceDetails({
                                                                artgNumber: event.target.value,
                                                            }));
                                                        } }) }), _jsx(SAFormItem, { label: "Sponsor", name: "sponsor", children: _jsx(StyledInput, { onChange: (event) => {
                                                            dispatch(setDeviceDetails({
                                                                sponsor: event.target.value,
                                                            }));
                                                        } }) }), _jsx(SAFormItem, { label: "Manufacturer", name: "manufacturer", children: _jsx(StyledInput, { onChange: (event) => {
                                                            dispatch(setDeviceDetails({
                                                                manufacturer: event.target.value,
                                                            }));
                                                        } }) })] }), _jsx(SAColumn, { span: 4, children: _jsx(SAFormItem, { label: "ARTG Public Summary Sheet", name: "artgSummarySheetUrl", children: _jsx(FileOptionsDropdown, { src: deviceInfo.artgSummarySheetUrl, onMenuItemClick: (option) => {
                                                        setUploadingFile({
                                                            url: deviceInfo.artgSummarySheetUrl ?? null,
                                                            key: "artgSummarySheetUrl",
                                                            fileType: UploadFileType.Pdf,
                                                        });
                                                        onMenuItemClick(option);
                                                    }, Fallback: deviceInfo.artgSummarySheetUrl ? UploadedPdfIcon : PdfIcon, type: UploadFileType.Pdf }) }) }), _jsx(SAColumn, { span: 4, children: _jsx(SAFormItem, { label: "Laboratory Verification Sheet", name: "laboratorySheetUrl", children: _jsx(FileOptionsDropdown, { src: deviceInfo.laboratorySheetUrl, onMenuItemClick: (option) => {
                                                        setUploadingFile({
                                                            url: deviceInfo.laboratorySheetUrl ?? null,
                                                            key: "laboratorySheetUrl",
                                                            fileType: UploadFileType.Pdf,
                                                        });
                                                        onMenuItemClick(option);
                                                    }, Fallback: deviceInfo.laboratorySheetUrl ? UploadedPdfIcon : PdfIcon, type: UploadFileType.Pdf }) }) }), _jsx(SAColumn, { span: 4, children: _jsx(SAFormItem, { label: "Specification Sheet", name: "specificationSheetUrl", children: _jsx(FileOptionsDropdown, { src: deviceInfo.specificationSheetUrl, onMenuItemClick: (option) => {
                                                        setUploadingFile({
                                                            url: deviceInfo.specificationSheetUrl ?? null,
                                                            key: "specificationSheetUrl",
                                                            fileType: UploadFileType.Pdf,
                                                        });
                                                        onMenuItemClick(option);
                                                    }, Fallback: deviceInfo.specificationSheetUrl ? UploadedPdfIcon : PdfIcon, type: UploadFileType.Pdf }) }) })] })] }), _jsx(FileUploadDrawer, { isOpen: isOpenUploadPanel, setOpen: setIsOpenUploadPanel, uploadPrefix: UploadPrefix.UrineDevice, uploadUrl: uploadingFile.url, type: uploadingFile.fileType, setUploadUrl: async (uploadedUrl) => {
                                setUploadingFile((preState) => ({
                                    ...preState,
                                    url: uploadedUrl,
                                }));
                            }, onSaveFile: async () => {
                                if (!uploadingFile.key)
                                    return;
                                dispatch(setIsLoading(true));
                                if (uploadingFile.key === "sideImageUrls" && uploadingFile.url) {
                                    const sideImageUrls = [...(deviceInfo.sideImageUrls ?? [])];
                                    sideImageUrls.push(uploadingFile.url);
                                    await dispatch(setDeviceDetails({
                                        [uploadingFile.key]: sideImageUrls,
                                    }));
                                }
                                else {
                                    await dispatch(setDeviceDetails({
                                        [uploadingFile.key]: uploadingFile.url,
                                    }));
                                }
                                setIsOpenUploadPanel(false);
                                setUploadingFile(defaultUploadingFile);
                                dispatch(setIsLoading(false));
                            } })] }), _jsx(AddStockForm, { dataSource: [] })] }) }));
};
export default UrineDeviceManager;
const NewDeviceContainer = styled.div `
  background-color: #edeef0;
  margin: 2.5rem 2rem;
  height: 100%;
  padding: 2rem;
  border-radius: 0.25rem;
`;
const CreateFormWrapper = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
`;
const StyledInput = styled(Input) `
  width: 100%;

  &:disabled {
    background-color: #ffffff !important;
    color: #000000 !important;
    cursor: not-allowed !important;
  }
`;
const HeaderRow = styled(SARow) `
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;
