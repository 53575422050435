/* eslint-disable @typescript-eslint/no-explicit-any */
import { combineReducers } from "@reduxjs/toolkit";
import { message } from "antd";
import oralScreenDeviceReduce from "src/pages/TestingDevicePage/OralFluidDrugScreen/store/slice";
import urineScreenDeviceReduce from "src/pages/TestingDevicePage/UrineDrugScreenDevice/store/slice";
import oralCollectionDeviceReduce from "src/pages/TestingDevicePage/OralFluidCollection/store/slice";
import breathalyserReduce from "src/pages/TestingDevicePage/Breathalyser/store/slice";
import { archiveTestingDevice, deleteTestingDevice, duplicateTestingDevice, restoreTestingDevice, updateInUseTestingDevice, } from "./action";
export const handleCommonActionResult = (builder, action, successMessage, failureMessage, testTypeRecordIndex) => {
    builder
        .addCase(action.fulfilled, (state, currentAction) => {
        if (currentAction.payload.status === "fail" || !currentAction.payload.data) {
            message.error(failureMessage);
        }
        else {
            if (currentAction.payload.data.testTypeRecordIndex === testTypeRecordIndex) {
                message.success(successMessage);
            }
        }
    })
        .addCase(action.rejected, () => {
        message.error(failureMessage);
    });
};
export const applyCommonActionHandlers = (builder, testTypeRecordIndex) => {
    handleCommonActionResult(builder, updateInUseTestingDevice, "Successfully updated the usage of the device", "Failed to update the usage of the device", testTypeRecordIndex);
    handleCommonActionResult(builder, restoreTestingDevice, "Successfully restored the device", "Failed to restore the device", testTypeRecordIndex);
    handleCommonActionResult(builder, archiveTestingDevice, "Successfully archived the device", "Failed to archive the device", testTypeRecordIndex);
    handleCommonActionResult(builder, duplicateTestingDevice, "Successfully duplicated the device", "Failed to duplicate the device", testTypeRecordIndex);
    handleCommonActionResult(builder, deleteTestingDevice, "Successfully deleted the device", "Failed to delete the device", testTypeRecordIndex);
};
const deviceManagementReducer = combineReducers({
    urineScreenDevicePage: urineScreenDeviceReduce,
    oralScreenDevicePage: oralScreenDeviceReduce,
    oralCollectionDevicePage: oralCollectionDeviceReduce,
    breathalyserPage: breathalyserReduce,
});
export default deviceManagementReducer;
