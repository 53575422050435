import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Breadcrumb, Radio, Tabs } from "antd";
import { uniqueId } from "lodash";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
export const PageLayoutWithExtraTab = ({ children, routes, tabLevel = 2, }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const currentLocation = location.pathname.split("/");
    const paths = currentLocation.filter(Boolean).map((path) => `/${path}`);
    const handleChangeTab = (activeKey) => {
        const currentPaths = currentLocation.filter(Boolean);
        const minLevel = Math.min(...routes.map((route) => route.level));
        const prefixRoutes = [];
        const newPaths = currentPaths.reduce((accumulatedPaths, path, index) => {
            const currentLevel = index + minLevel;
            if (index < minLevel) {
                const unavailableRoute = routes.find((route) => route.level === currentLevel && route.path === `/${path}`);
                if (!unavailableRoute) {
                    prefixRoutes.push(path);
                }
            }
            const matchingRoute = routes.find((route) => route.level === currentLevel && route.path === `/${path}` && route.level !== tabLevel);
            if (matchingRoute) {
                accumulatedPaths.push(path);
            }
            return accumulatedPaths;
        }, []);
        const newRoute = routes.find((route) => route.level === tabLevel && route.path === activeKey);
        if (newRoute) {
            newPaths.push(newRoute.path.slice(1));
        }
        const finalPaths = [...new Set([...prefixRoutes, ...newPaths])];
        navigate(`/${finalPaths.join("/")}`);
    };
    return (_jsxs(PageLayoutWithExtraTabWrapper, { children: [_jsx(Header, { children: _jsx(CustomBreadcrumb, { separator: ">", children: paths.map((path, index) => {
                        const fullPath = paths.slice(0, index + 1).join("");
                        let currentRoute = routes.find((route) => route.level === index + 1 && path === route.path);
                        if (!currentRoute) {
                            currentRoute = routes
                                .flatMap((route) => route.subRoutes || [])
                                .find((subRoute) => subRoute.path === path);
                            if (!currentRoute)
                                return null;
                        }
                        const BreadcrumbComponent = index === paths.length - 1 ? ActiveBreadcrumb : Breadcrumb.Item;
                        return (_jsx(BreadcrumbComponent, { children: _jsx(Link, { to: fullPath, children: currentRoute.name }) }, path));
                    }) }) }), _jsx(CustomTabs, { onChange: handleChangeTab, type: "card", activeKey: routes.find((route) => route.level === tabLevel && location.pathname.includes(route.path))
                    ?.path ?? currentLocation[-1], tabPosition: "top", children: routes
                    .filter((route) => route.level === tabLevel)
                    .map((route) => {
                    const active = location.pathname.includes(route.path);
                    return (_jsxs(CustomTabPane, { tab: route.name, disabled: route.disabled, children: [route.subRoutes && (_jsx(GroupButton, { value: paths[paths.length - 1], children: route.subRoutes?.map((subRoute) => (_jsx(Radio.Button, { value: subRoute.path, onClick: () => navigate(`${location.pathname.replace(paths[paths.length - 1], subRoute.path)}`), children: subRoute.name }, uniqueId()))) })), active ? children : null] }, route.path));
                }) })] }));
};
const ActiveBreadcrumb = styled(Breadcrumb.Item) `
  color: white;
  border-bottom: 0.2rem solid #01b4d2;
  font-weight: bold;
`;
const CustomBreadcrumb = styled(Breadcrumb) `
  padding-inline: 2.5rem;
  padding-top: 0.5rem;
  color: white;

  a {
    color: white !important;
  }

  .ant-breadcrumb-separator {
    color: white;
  }
`;
const CustomTabPane = styled(Tabs.TabPane) `
  height: 100%;
`;
const CustomTabs = styled(Tabs) `
  margin-top: -2.5rem;
  height: 100%;

  .ant-tabs-content-holder {
    padding: 2rem;
  }
  .ant-tabs-nav-wrap {
    justify-content: flex-start;
    margin-left: 2.5rem;
    margin-top: 0.7rem;
  }

  .ant-tabs-nav-list {
    column-gap: 0.625rem;
  }

  .ant-tabs-nav {
    border: none;
    margin: 0 0;
  }

  .ant-tabs-tab {
    color: white;
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
    -webkit-box-pack: center !important;
    justify-content: center !important;
    background-color: rgb(1, 180, 210) !important;
    min-width: 10rem !important;
    border: none !important;
    text-align: center !important;
    padding: 0.25rem !important;
    padding-inline: 1rem !important;
  }

  .ant-tabs-tab-active {
    background-color: white !important;
    box-shadow: 2px -2px 4px 0px #00000012;
  }

  .ant-tabs-tab-active:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 7%;
    width: 86%;
    height: 0.125rem;
    background-color: #fdba00;
  }

  .ant-tabs-content {
    display: block;
  }
`;
const PageLayoutWithExtraTabWrapper = styled.div `
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  flex: 1;
  justify-content: flex-start;
`;
const Header = styled.div `
  height: 5rem;
  background-color: #2c7796;
  width: 100%;
`;
const GroupButton = styled(Radio.Group) `
  gap: 1rem;
  margin-right: auto;
  flex-wrap: wrap;
  margin-left: 0.5rem;
  margin-bottom: 3rem;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  label {
    margin-right: 1rem;

    span:nth-child(2) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
    }
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }

  .ant-radio-button-wrapper {
    border-radius: 0.3rem !important;
    border: 0;
    color: #4b5767;
    width: 15rem;
    background-color: #edeef0;
  }

  .ant-radio-button-wrapper-checked {
    background-color: #1a8cff;
    color: #ffffff;
    border-radius: 0.3rem !important;
    border: 0;
  }
`;
