import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CalendarOutlined, DollarOutlined, EllipsisOutlined, PlusOutlined, } from "@ant-design/icons";
import { uuidv4 } from "@antv/xflow-core";
import { Button, Dropdown, Form, Input, Menu, Select, Space, Table, Typography, } from "antd";
import { useState } from "react";
import styled from "styled-components";
import AddStockIcon from "src/assets/svg/device-management/add-stock-icon.svg";
import DocumentIcon from "src/assets/svg/device-management/document.svg";
import SADivider from "src/components/Form/SADivider";
import SAFormItem from "src/components/Form/SAFormItem";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { ExcelIcon } from "src/assets/svg/brands";
import StockSummary from "./StockSummary";
const AddStockForm = ({ dataSource }) => {
    const [form] = Form.useForm();
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "30"],
    });
    const handleTableChange = (newPagination) => {
        setPagination(newPagination);
    };
    return (_jsxs(AddStockFormContainer, { children: [_jsx(HighlightedStockSummary, {}), _jsxs(Space, { align: "start", children: [_jsx(AddStockIcon, {}), _jsx(Typography.Title, { level: 5, children: "Stock Entry" })] }), _jsx(SADivider, {}), _jsx(WarningText, { children: "Please ensure this stock entry matches the same device and lot number. To add stock for a different Lot Number, please add new device." }), _jsx(StockEntryForm, { form: form, labelCol: { span: 10 }, wrapperCol: { span: 24 }, colon: false, children: _jsxs(SARow, { children: [_jsx(SAColumn, { span: 6, children: _jsx(SAFormItem, { label: "Batch/Order Number", name: "batchNumber", requiredText: "Please fill in the batch number", children: _jsx(Input, {}) }) }), _jsx(SAColumn, { span: 6, children: _jsx(SAFormItem, { label: "Number of Units", name: "noOfUnits", requiredText: "Please fill in the number of units", children: _jsx(Input, {}) }) }), _jsx(SAColumn, { span: 6, children: _jsx(SAFormItem, { label: "Unit Price", name: "unitPrice", requiredText: "Please fill in the unit price", children: _jsx(Input, { prefix: _jsx(DollarOutlined, {}) }) }) }), _jsx(SAColumn, { span: 6, children: _jsxs(Button, { type: "primary", children: ["Add Stock ", _jsx(PlusOutlined, {})] }) }), _jsx(SAColumn, { span: 18, children: _jsx(SAFormItem, { label: "Notes", name: "note", labelCol: { span: 3 }, wrapperCol: { span: 24 }, style: { paddingLeft: "1rem" }, children: _jsx(Input, {}) }) })] }) }), _jsxs(TableContainer, { children: [_jsxs(TableFilter, { children: [_jsx(CalendarOutlined, {}), _jsx(Select, {})] }), _jsxs(Table, { dataSource: dataSource.map((item) => ({ ...item, id: uuidv4() })), rowKey: "id", bordered: false, pagination: {
                            current: pagination.current,
                            pageSize: pagination.pageSize,
                        }, onChange: handleTableChange, children: [_jsx(Table.Column, { title: "Date", dataIndex: "date", sorter: true }, "date"), _jsx(Table.Column, { title: "Batch/Order No.", dataIndex: "batchNumber", sorter: true }, "batchNumber"), _jsx(Table.Column, { title: "Units", dataIndex: "noOfUnits", sorter: true }, "noOfUnits"), _jsx(Table.Column, { title: "Unit Price", dataIndex: "unitPrice", sorter: true }, "unitPrice"), _jsx(Table.Column, { title: "Total", dataIndex: "total", sorter: true }, "total"), _jsx(Table.Column, { title: "Entered By", dataIndex: "enteredBy", sorter: true }, "enteredBy"), _jsx(Table.Column, { title: "Note", dataIndex: "note", align: "center", render: (text) => _jsx(DocumentIcon, { color: text ? "#1A8CFF" : "#EDEEF0" }) }, "note"), _jsx(Table.Column, { title: "", align: "center", render: (id) => (_jsx(Dropdown, { overlay: _jsxs(CustomMenu, { children: [_jsx(Menu.Item, { onClick: async () => {
                                                    console.log(id);
                                                }, children: "Edit" }), _jsx(Menu.Item, { children: "Delete" })] }), trigger: ["click"], children: _jsx(Button, { type: "link", children: _jsx(EllipsisOutlined, {}) }) })) }, "id")] }), _jsxs(SizeChangerContainer, { "$hidden": !dataSource.length, children: [_jsx(Typography.Text, { children: "Show" }), _jsxs(PaginationSizeChanger, { value: pagination.pageSize, onSelect: (size) => {
                                    setPagination({ ...pagination, pageSize: size });
                                }, children: [_jsx(Select.Option, { value: 10, children: "10" }), _jsx(Select.Option, { value: 15, children: "15" }), _jsx(Select.Option, { value: 20, children: "20" })] }), _jsx(ExportButton, { icon: _jsx(ExcelIcon, {}), children: "Export file" })] })] })] }));
};
export default AddStockForm;
const AddStockFormContainer = styled.div `
  margin: 2rem 0;
`;
const HighlightedStockSummary = styled(StockSummary) `
  & div:nth-child(3) {
    background-color: #e1e2e5;
  }
`;
const StockEntryForm = styled(Form) `
  background-color: #e1e2e5;
  width: 100%;
  padding: 1rem;
  border-radius: 0.25rem;
  margin: 1rem 0;
`;
const TableContainer = styled.div `
  width: 100%;
  position: relative;
  margin: 1rem 0;
`;
const TableFilter = styled.div `
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  margin-left: auto;
  margin-bottom: 1rem;
`;
const SizeChangerContainer = styled.div `
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  min-width: 5rem;
  bottom: 0.5rem;
  white-space: nowrap;
  display: ${({ $hidden }) => ($hidden ? "none" : "flex")};
`;
const PaginationSizeChanger = styled(Select) `
  width: fit-content;
`;
const ExportButton = styled(Button) `
  color: #01b4d2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;
const CustomMenu = styled(Menu) `
  width: 10rem;
  border: 0.025rem solid #01b4d2;
  border-radius: 0.25rem;
`;
const WarningText = styled.p `
  color: #2a44ff;
`;
